import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
} from 'variables/constants';

export function useDataDog() {
  useEffect(() => {
    if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && DATADOG_SITE) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: DATADOG_SITE,
        service: 'vdx-react-app',
        env: process.env.NODE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        allowedTracingUrls: [
          'https://vendelux.com',
          // Include all vendelux.com subdomains except for id.vendelux.com; this will cause auth0 logins to fail because of a missing header.
          /https:\/\/(?!(id|analytics-ph)\.)([a-zA-Z0-9-]+\.)?vendelux\.com/,
        ],
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        enablePrivacyForActionName: true,
      });
    }
  }, []);
}
